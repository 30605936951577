<template>
    <div>
        <div class="title">
            <h2>{{ $t('menu.parceiros') }}</h2>
        </div>
        <div class="ajus">
            <div class="flex justify-center items-center">
                <a href="https://comunidadflyoficial.com/events/" target="_black">
                    <img src="../assets/cfo.png" />
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="https://pokemongobauru.blogspot.com/" target="_black">
                    <img src="../assets/bauru.png" />
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="https://eduhunterplay.com/" target="_black">
                    <img src="../assets/FGFGF.png" />
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="https://clubego.org/" target="_black">
                    <img src="../assets/clubego.jpeg" />
                </a>
            </div>
        </div>

        <div class="title mt-10">
            <h2>Telegram</h2>
        </div>
        <div class="telegram">
            <div>
                ★ CoordsGO Pokemon GO Telegram ★
                <a href="https://t.me/coordsgo" target="_black">https://t.me/coordsgo</a>
            </div>
            <br />
            <div>
                Projeto Vivillons
                <a href="https://t.me/+JhdG6AitLBtkYTUx" target="_black">https://t.me/+JhdG6AitLBtkYTUx</a>
            </div>
            <br />
            <div>
                Lendários Brasil Pokemon GO
                <a href="https://t.me/PokemongolendariosBr" target="_black">https://t.me/PokemongolendariosBr</a>
            </div>
            <br />
            <div>
                Fly GPS Pokémon GO
                <a href="https://t.me/FlyGpsPokemonGo" target="_black">https://t.me/FlyGpsPokemonGo</a>
            </div>
            <br />
            <div>
                Pokemon Go Brasil
                <a href="https://t.me/pokebrasilgo" target="_black">https://t.me/pokebrasilgo</a>
            </div>
            <br />
            <div>
                Pokemon GO - Todos Juntos
                <a href="https://t.me/PokemonTodosJuntos" target="_black">https://t.me/PokemonTodosJuntos</a>
            </div>
            <br />

        </div>

    </div>
</template>

<script>
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
    data: () => ({
        open: false
    }),
    mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Parceiros'
        })
    },
}
</script>

<style lang="scss" scoped>
img {
    max-width: 137px;
    max-height: 110px;
}

.ajus {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    gap: 3px;
}

.title {
    h2 {
        display: flex;
        justify-content: space-between;
        color: #673bb7;
        margin: 10px 0;
        font-size: 23px;
        border-bottom: 1px solid #673bb7;
        font-weight: bold;
    }
}

h3 {
    padding-bottom: 5px;
    padding-top: 30px;
    font-size: 22px;
    font-weight: bold;
}

.telegram {
    div {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    a {
        padding: 10px;
        color: #673ab7;
        padding-top: 5px;
        padding-bottom: 5px;
        text-decoration: none;
    }
}

.link {
    background-color: #673ab7;
    padding: 10px;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration: none;
}
</style>
